<template>
  <v-dialog
    persistent
    v-model="modalNewPayment"
    :max-width="userSelected ? '60%' : '35%'"
  >
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline"
          >Realizar cobro
          {{ userSelected ? ` a ${user.name} ${user.surname}` : "" }}</span
        >
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="cancel"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
        <v-text-field
          v-model="search"
          v-if="!userSelected"
          clearable
          :loading="loading"
          rounded
          flat
          filled
          placeholder="Ejemplo : +50499987659"
          hide-details
          v-on:keyup.enter="searchUser"
          :label="`Buscar usuario por número de teléfono`"
          prepend-inner-icon="fa-search"
          style="width: 100%"
          class="d-none d-md-flex mt-5 mb-0"
        />
        <v-btn
          color="primary"
          rounded
          :loading="loading"
          v-if="!userSelected"
          class="flex-grow-1 white--text mt-10"
          block
          :elevation="0"
          :minHeight="56"
          @click="searchUser()"
        >
          Buscar usuario
        </v-btn>
      </v-card-title>
      <v-card-text
        style="max-height: 60vh; min-height: 40vh; overflow-y: scroll"
      >
        <v-snackbar top :timeout="3000" v-model="snackbar">{{
          snackbarText
        }}</v-snackbar>
        <v-stepper light v-if="userSelected" v-model="e1">
          <v-stepper-header>
            <v-stepper-step
              class="stepper"
              title="Clic para cambiar"
              :complete="e1 > 1"
              @click="changeUser()"
              step="1"
              >Seleccionar usuario
              <small class="mt-2 ml-2 font-weight-bold"
                >{{ user.name }} {{ user.surname }}</small
              >
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              class="stepper"
              title="Clic para cambiar"
              :complete="e1 > 2"
              @click="changeOrder()"
              step="2"
              >Seleccionar orden
              <small v-if="order.shortId" class="mt-2 ml-2 font-weight-bold">{{
                order.shortId
              }}</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              class="stepper"
              title="Clic para cambiar"
              :complete="e1 > 3"
              @click="changeCard()"
              step="3"
              >Seleccionar tarjeta
              <small v-if="card.tokens" class="mt-2 ml-2 font-weight-bold">{{
                card.tokens.bac
              }}</small>
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step class="stepper" :complete="e1 > 4" step="4"
              >Confirmar cobro</v-stepper-step
            >
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="2">
              <h2 class="mt-4">Seleccionar orden</h2>
              <v-data-table
                :headers="headersOrders"
                :items="orders"
                :items-per-page="10"
                :loading="loading"
                :sort-desc="true"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50, 100, 250],
                }"
              >
                <template v-slot:[`item.options`]="{ item }">
                  <v-btn
                    @click="setOrder(item)"
                    small
                    color="primary"
                    class="mr-3"
                    >Seleccionar</v-btn
                  >
                </template>
                <template v-slot:[`item.orderTotal`]="{ item }">
                  {{ item.orderTotal | currency }}
                </template>
                <template v-slot:[`item.progressCreatedAt`]="{ item }">
                  {{ item.progressCreatedAt | filterDate }}
                </template>
              </v-data-table>
            </v-stepper-content>

            <v-stepper-content step="3">
              <h2 class="mt-4">Seleccionar tarjeta</h2>
              <v-data-table
                :headers="headersCards"
                :items="cards"
                :items-per-page="10"
                :loading="loading"
                :sort-desc="true"
                :footer-props="{
                  itemsPerPageOptions: [10, 25, 50, 100, 250],
                }"
              >
                <template v-slot:[`item.name`]="{ item }">
                  {{ item.name | capitalize }}
                </template>

                <template v-slot:[`item.validThru`]="{ item }">
                  {{ item.validThru | filterValidDate }}
                </template>

                <template v-slot:[`item.options`]="{ item }">
                  <v-btn
                    @click="setCard(item)"
                    small
                    color="primary"
                    class="mr-3"
                    >Seleccionar</v-btn
                  >
                </template>
              </v-data-table>
            </v-stepper-content>

            <v-stepper-content step="4">
              <v-container v-if="cardSelected && e1 == 4">
                <v-row>
                  <v-col cols="12" sm="4" md="4">
                    <div class="container-details">
                      <p class="font-weight-bold">Detalles del cliente</p>
                      <v-divider class="mb-3"></v-divider>
                      <span class="font-weight-bold">Nombre del cliente</span>
                      <p>{{ user.name }} {{ user.surname }}</p>
                      <span class="font-weight-bold">Correo electrónico</span>
                      <p>{{ user.email }}</p>
                      <span class="font-weight-bold">Número de teléfono</span>
                      <p>{{ user.phone }}</p>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <div class="container-details">
                      <p class="font-weight-bold">Detalles de la orden</p>
                      <v-divider class="mb-3"></v-divider>
                      <span class="font-weight-bold">ID de la orden</span>
                      <p>{{ order.shortId }}</p>
                      <span class="font-weight-bold">Nombre del comercio</span>
                      <p>{{ order.businessName }}</p>
                      <span class="font-weight-bold">Total de la orden</span>
                      <p>
                        {{ order.orderTotal | currency }}
                        <v-menu
                          v-model="menuEdit"
                          :close-on-content-click="false"
                          :nudge-width="200"
                          offset-x
                          style="border-radius: 20px"
                        >
                          <template v-slot:activator="{ on }">
                            <i
                              @click="newOrderTotal = order.orderTotal"
                              title="Clic para cambiar el monto a cobrar"
                              v-on="on"
                              class="far fa-edit ml-3 edit"
                            ></i>
                          </template>

                          <div class="change-order-total">
                            <h2 class="mt-4 font-weight-bold">
                              Total de la orden
                            </h2>
                            <v-divider class="mb-3"></v-divider>
                            <input
                              class="control-input control-input-number"
                              type="number"
                              placeholder="Ingrese la posición"
                              v-model.number="newOrderTotal"
                            />
                            <v-btn
                              color="primary"
                              block
                              class="mt-5"
                              @click="changeOrderTotal"
                            >
                              Aceptar
                            </v-btn>
                          </div>
                        </v-menu>
                      </p>
                    </div>
                  </v-col>

                  <v-col cols="12" sm="4" md="4">
                    <div class="container-details">
                      <p class="font-weight-bold">Detalles de la tarjeta</p>
                      <v-divider class="mb-3"></v-divider>
                      <span class="font-weight-bold">Nombre del titular</span>
                      <p>{{ card.name }}</p>
                      <span class="font-weight-bold">Número de tarjeta</span>
                      <p>{{ card.tokens.bac }}</p>
                      <span class="font-weight-bold">Válida hasta</span>
                      <p>{{ cardDate }}</p>
                    </div>
                  </v-col>
                </v-row>
                <v-row align="center" justify="end">
                  <v-col cols="12" sm="4" md="4">
                    <v-btn
                      color="primary"
                      block
                      @click="collect()"
                      class="mt-5"
                    >
                      Ejecutar cobro
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-container v-if="e1 == 5">
                <v-row>
                  <v-col
                    style="text-align: center; font-size: 20px"
                    sm="12"
                    md="12"
                    cols="12"
                  >
                    <i
                      v-if="success"
                      style="font-size: 30px"
                      class="fas fa-check"
                    ></i>
                    <i
                      v-if="!success"
                      style="font-size: 30px; color: red"
                      class="fas fa-times"
                    ></i>
                    <p class="mt-5">
                      {{
                        success
                          ? "Cobro realizado exitosamente."
                          : "Ocurrió un error al realizar el pago"
                      }}
                    </p>
                    <p v-if="!success">
                      Error {{ payResponse.data.reasonCode }} :
                      {{ payResponse.data.reasonCodeDescription }}
                    </p>
                    <p v-if="success">
                      Referencia : {{ payResponse.data.referenceNumber }}
                    </p>
                  </v-col>
                </v-row>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <!-- <v-container v-if="loading">
                <v-row>
                    <v-col v-for="(item, index) of [1,2,3,4,5,6,7,8]" :key="index" cols="12" sm="12" md="12">
                         <v-skeleton-loader
                            type="list-item"
                            ></v-skeleton-loader>
                    </v-col>
                </v-row>

            </v-container> -->

        <v-container v-if="!userSelected && !loading" fluid>
          <v-overlay class="loading-center" :value="saving">
            <lottie
              :options="defaultOptions"
              :height="300"
              :width="400"
              v-on:animCreated="handleAnimation"
            />
          </v-overlay>

          <v-row>
            <v-col
              cols="12"
              :md="userSelected ? '4' : '12'"
              :sm="userSelected ? '4' : '12'"
            >
              <v-row v-if="!userSelected">
                <v-col cols="12" sm="12" md="12">
                  <p>Resultado:</p>
                  <v-divider></v-divider>
                </v-col>

                <span class="ml-7" v-if="users.length == 0"
                  >Sin resultados :(</span
                >

                <v-list style="background: white !important">
                  <v-list-item
                    v-for="(user, index) in users"
                    :key="index"
                    @click="getUserCard(user)"
                  >
                    <v-list-item-avatar>
                      <v-img
                        :src="require('@/assets/user.svg')"
                        :lazy-src="require('@/assets/user.svg')"
                      ></v-img>
                    </v-list-item-avatar>

                    <v-list-item-content>
                      <v-list-item-title
                        >{{ user.name }} {{ user.surname }}</v-list-item-title
                      >
                      <v-list-item-subtitle
                        v-if="user.email"
                        v-html="user.email"
                      ></v-list-item-subtitle>
                      <v-list-item-subtitle
                        v-if="user.phone"
                        v-html="user.phone"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import axios from "axios";

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "HNL",
  minimumFractionDigits: 2,
});

export default {
  name: "payments",
  components: {
    lottie: Lottie,
  },
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      search: "",
      snackbarText: "",
      itemToEdit: {},
      menuEdit: false,
      itemToDelete: null,
      payments: [],
      e1: 1,
      success: false,
      users: [],
      user: {},
      cards: [],
      card: [],
      orders: [],
      order: [],
      newOrderTotal: 0,
      orderSelected: false,
      userSelected: false,
      cardSelected: false,
      searchResult: [],

      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      modalNewPayment: true,
      anim: null,
      animationSpeed: 1.1,
      payResponse: null,
      headersCards: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Token",
          value: "tokens.bac",
        },
        {
          text: "Termina en",
          value: "endsIn",
        },
        {
          text: "Marca",
          value: "brand",
        },
        {
          text: "Válida hasta",
          value: "validThru",
        },

        {
          value: "options",
        },
      ],
      headersOrders: [
        {
          text: "Order ID",
          value: "shortId",
          align: "center",
          width: "200px",
        },

        {
          text: "Negocio",
          value: "businessName",
        },

        {
          text: "Fecha",
          value: "progressCreatedAt",
          align: "center",
        },

        {
          text: "Estado del Pago",
          value: "orderPaymentStatus",
          align: "center",
        },

        {
          text: "Monto Total",
          value: "orderTotal",
        },

        { value: "options", width: "152" },
      ],
      headers: [
        {
          text: "Nombre",
          value: "name",
        },
        {
          text: "Pais",
          value: "country",
        },
        {
          text: "Activo",
          value: "active",
        },

        {
          text: "Creada",
          value: "modifiedAt",
        },

        {
          value: "options",
          align: "end",
        },
      ],
    };
  },

  filters: {
    currency(amount) {
      return formatter.format(amount);
    },
    filterValidDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate())
        .tz("America/Tegucigalpa")
        .format("MM/YYYY");
    },

    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
    capitalize(value) {
      let arr = value.split(" ");
      let name = "";

      arr.forEach((letter) => {
        name +=
          " " + letter.charAt(0).toUpperCase() + letter.slice(1).toLowerCase();
      });
      return name;
    },
  },

  watch: {
    user() {
      if (this.user[".key"]) {
        this.loading = true;
        this.$binding(
          "orders",
          db.collection("orders").where("userId", "==", this.user[".key"])
        ).then((orders) => {
          this.orders = this.orders.filter(
            (item) => item.orderPaymentStatus != "paid"
          );
          this.loading = false;
          this.e1 = 2;
        });
      }
    },
    order() {
      if (this.user[".key"] && this.order.businessId) {
        this.e1 = 3;
        this.loading = true;

        this.$binding(
          "cards",
          db
            .collection("users")
            .doc(this.user[".key"])
            .collection("cards")
            .where("active", "==", true)
            .where("validated", "==", true)
            .where("deleted", "==", false)
        ).then((cards) => {
          this.orderSelected = true;
          this.loading = false;
        });
      }
    },
  },
  computed: {
    // getUsers() {
    //     if (this.search && this.search.length > 2)
    //         return this.users.filter(item  => ((`${item.name} ${item.surname}`.toUpperCase().includes(this.search.toUpperCase()))) || ( item.email && (item.email.toUpperCase().startsWith(this.search.toUpperCase()))) || ( item.phone && (item.phone.toUpperCase().includes(this.search.toUpperCase())))  )
    //     else
    //         return this.users.slice(0, 10);
    // },

    getIp() {
      return new Promise((resolve, reject) => {
        axios.get("https://api.ipify.org/?format=json").then((res) => {
          resolve(res.data.ip);
        });
      });
    },

    cardDate() {
      return moment(this.card.validThru.toDate())
        .tz("America/Tegucigalpa")
        .format("MM/YY");
    },
    getAmount: {
      get: function () {
        return formatter.format(this.order.orderTotal);
      },
      set: function (newValue) {
        this.order.orderTotal = newValue;
      },
    },
    getPayments() {
      if (this.$store.state.search) {
        return this.payments.filter(
          (item) =>
            (item.name &&
              item.name
                .toUpperCase()
                .indexOf(this.$store.state.search.toUpperCase()) != -1) ||
            (item.country &&
              item.country
                .toUpperCase()
                .indexOf(this.$store.state.search.toUpperCase()) != -1)
        );
      } else {
        return this.payments;
      }
    },
  },
  methods: {
    changeOrderTotal() {
      this.order.orderTotal = this.newOrderTotal;
      this.menuEdit = false;
    },
    cancel() {
      this.$emit("cancel");
    },
    searchUser() {
      this.users = [];
      this.loading = true;
      db.collection("users")
        .where("phone", "==", this.search)
        .get()
        .then((response) => {
          if (response.size > 0) {
            response.forEach((item) => {
              let user = item.data();
              user[".key"] = item.id;
              this.users.push(user);
            });
            this.loading = false;
          } else {
            this.snackbarText = "Usuario no encontrado.";
            this.snackbar = true;
            this.loading = false;
          }
        });
    },
    handleSuccess(response) {
      if (response.data.data) {
        this.payResponse = response.data;
        db.collection("orders")
          .doc(this.order[".key"])
          .update({
            orderPaymentStatus: "paid",
            paymentData: response.data.data,
          })
          .then(() => {
            this.saving = false;
            this.success = true;
            this.e1 = 5;
          });
      }
    },

    handleFail(err) {
      if (
        err.response &&
        err.response.data &&
        err.response.data.data &&
        err.response.status == 400 &&
        err.response.data.hasOwnProperty("processed")
      ) {
        this.payResponse = err.response.data;
        db.collection("orders")
          .doc(this.order[".key"])
          .update({
            orderPaymentStatus: "failedCapture",
          })
          .then(() => {
            this.success = false;
            this.saving = false;
            this.e1 = 5;
          });
      } else if (
        err.response &&
        err.response.data &&
        err.response.data.message
      ) {
        this.snackbarText = err.response.data.message;
        this.snackbar = true;
        this.saving = false;
      } else {
        this.snackbarText =
          "Ocurrió un error inesperado, inténtelo nuevamente.";
        this.snackbar = true;
        this.saving = false;
      }
    },

    changeCard() {
      if (this.e1 != 5) {
        this.e1 = 3;
        this.cardSelected = false;
      }
    },

    changeOrder() {
      if (this.e1 != 5) {
        this.e1 = 2;
        this.cardSelected = false;
        this.orderSelected = false;
      }
    },

    changeUser() {
      if (this.e1 != 5) {
        this.card = {};
        this.cardSelected = false;
        this.e1 = 1;
        this.orderSelected = false;
        this.userSelected = false;
      }
    },

    async collect() {
      let ip = await this.getIp;
      if (
        ip &&
        this.cardDate &&
        this.card.tokens.bac &&
        this.order.orderTotal &&
        this.order.shortId &&
        this.user[".key"]
      ) {
        this.saving = true;

        let data = {
          expDate: this.cardDate.replace("/", ""),
          cardNumber: this.card.tokens.bac,
          orderTotal: this.order.orderTotal,
          ipAddress: ip,
          orderNumber: this.order.shortId,
          currency: "340",
          transactionCode: 8,
          customerReference: "aT2Ab3bunJ80mn72Dz6q",
          userId: this.user[".key"],
        };

        axios({
          method: "POST",
          crossDomain: true,
          url: `${process.env.VUE_APP_FUNCTIONS_URL}/httpPaymentGatewayBacManualPay`,
          data,
        })
          .then((res) => {
            this.handleSuccess(res);
          })
          .catch((err) => {
            this.handleFail(err);
          });
      } else {
        this.snackbarText = "Debe completar todo los campos.";
        this.snackbar = true;
      }
    },
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    getUserCard(user) {
      this.userSelected = true;
      this.user = user;
    },

    setCard(card) {
      this.card = card;
      this.cardSelected = true;
      this.e1 = 4;
    },

    setOrder(order) {
      this.order = order;
    },
  },

  mounted() {
    this.$store.commit("setSearchTerm", "cobro");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;
    this.loading = false;
    // this.$binding("users", db.collection("users").where('orderCounter', '>', 0))
    // .then((users) => {
    //     this.loading = false
    // })
  },
};
</script>


<style>
.select-city {
  border-radius: 50px;
  padding: 0;
}

.card {
  width: 100%;
  background-color: rgba(53, 51, 50, 0.18);
  border-radius: 20px;
  padding: 10px;
}

.stepper {
  cursor: pointer;
}

.container-details {
  background-color: #c5c0c042;
  border-radius: 10px;
  padding: 15px;
}

.edit {
  cursor: pointer;
  color: gray;
}

.change-order-total {
  width: 400px;
  padding: 15px;
  height: 220px;
  background-color: white;
  border-radius: 20px;
}
</style>